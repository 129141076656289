<template>
  <div>
    <div class="content-header">
      <h1 class="section--header">QR Codes</h1>
      <input v-model="searchTerm" placeholder="Search" class="ml-auto search-input">
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="qrc-bulk-delete">
            <input type="checkbox" id="bulk-select-all" v-model="isAllSelected" @change="selectAll" />
            <label for="bulk-select-all">{{ isAllSelected ? 'Deselect All' : 'Select All' }}</label>
            <button v-if="selectedShortlinkIds.length > 0" class="btn btn-danger ml-auto" @click="deleteSelected" :disabled="selectedShortlinkIds.length === 0">Delete Selected</button>
          </div>

          <div class="qrc-list-wrapper">
            <div v-for="shortlink in paginatedShortlinks" :key="shortlink.id" class="qrc-card-container">
              <div class="image-container">
                <img class="qrc-image" :src="`https://api.principiadv.online/wp-content/uploads/qrcode/${shortlink.svg_name}`" alt="QR Code">
              </div>
              <div class="card-content">
                <div class="content-top">
                  <div class="top-info">
                    <p class="orb-typography p qrc-type">Website
                      <span :class="{'badge rounded-pill bg-danger': shortlink.post_status === 'draft', 'badge rounded-pill bg-success': shortlink.post_status !== 'draft'}">
                        {{ shortlink.post_status === 'draft' ? 'Suspended' : 'Live' }}
                      </span>
                      <span :class="{'badge rounded-pill bg-warning': shortlink.qrcode_link_type === 'direct', 'badge rounded-pill bg-primary': shortlink.qrcode_link_type !==
                      'shortlink'}">
                        {{ shortlink.qrcode_link_type === 'direct' ? 'Url diretto' : 'Url Shortlink' }}
                      </span>



                    </p>
                    <h2 class="orb-typography h2 no-margin"><button @click.prevent="toggleShortlinkSelection(shortlink.id)">{{ shortlink.title }}</button></h2>
                  </div>
                  <div class="qrc-actions sm-md-d-flex">
                    <div class="dropdown icon-drop">
                      <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><title>Icon</title><path fill="none" d="M0 0h24v24H0z"></path><path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path></svg>
                        Download
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item download-qrcode-file" :href="'https://api.principiadv.online/wp-content/uploads/qrcode/' + shortlink.png_name" target="_blank"
                             rel="noopener noreferrer">
                            Download PNG
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item download-qrcode-file" :href="'https://api.principiadv.online/wp-content/uploads/qrcode/' + shortlink.svg_name" target="_blank"
                             rel="noopener noreferrer">
                            Download SVG
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="dropdown icon-drop">
                      <router-link :to="{ name: 'ModificaQrcode', params: { id: shortlink.id }}" class="btn btn-light dropdown-toggle">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15" width="15" xmlns="http://www.w3.org/2000/svg">
                          <title>Icon</title>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
                        </svg>Edit
                      </router-link>
                    </div>


                    <div class="dropdown icon-drop">
                      <button @click="deleteShortlink(shortlink.id)" class="btn btn-sm btn-danger" style="margin-left: auto;">
                        <svg class="svg-icon" viewBox="0 0 20 20">
                          <path fill="none" d="M16.588,3.411h-4.466c0.042-0.116,0.074-0.236,0.074-0.366c0-0.606-0.492-1.098-1.099-1.098H8.901c-0.607,0-1.098,0.492-1.098,1.098c0,0.13,0.033,0.25,0.074,0.366H3.41c-0.606,0-1.098,0.492-1.098,1.098c0,0.607,0.492,1.098,1.098,1.098h0.366V16.59c0,0.808,0.655,1.464,1.464,1.464h9.517c0.809,0,1.466-0.656,1.466-1.464V5.607h0.364c0.607,0,1.1-0.491,1.1-1.098C17.688,3.903,17.195,3.411,16.588,3.411z M8.901,2.679h2.196c0.202,0,0.366,0.164,0.366,0.366S11.3,3.411,11.098,3.411H8.901c-0.203,0-0.366-0.164-0.366-0.366S8.699,2.679,8.901,2.679z M15.491,16.59c0,0.405-0.329,0.731-0.733,0.731H5.241c-0.404,0-0.732-0.326-0.732-0.731V5.607h10.983V16.59z M16.588,4.875H3.41c-0.203,0-0.366-0.164-0.366-0.366S3.208,4.143,3.41,4.143h13.178c0.202,0,0.367,0.164,0.367,0.366S16.79,4.875,16.588,4.875zM6.705,14.027h6.589c0.202,0,0.366-0.164,0.366-0.366s-0.164-0.367-0.366-0.367H6.705c-0.203,0-0.366,0.165-0.366,0.367S6.502,14.027,6.705,14.027z M6.705,11.83h6.589c0.202,0,0.366-0.164,0.366-0.365c0-0.203-0.164-0.367-0.366-0.367H6.705c-0.203,0-0.366,0.164-0.366,0.367C6.339,11.666,6.502,11.83,6.705,11.83z M6.705,9.634h6.589c0.202,0,0.366-0.164,0.366-0.366c0-0.202-0.164-0.366-0.366-0.366H6.705c-0.203,0-0.366,0.164-0.366,0.366C6.339,9.47,6.502,9.634,6.705,9.634z"></path>
                        </svg>
                        Elimina
                      </button>
                      <input type="checkbox" :value="shortlink.id" v-model="selectedShortlinkIds" class="ml-2">
                    </div>





                  </div>
                </div>
                <div class="content-bottom">
                  <div class="bottom-info">
                    <div class="info-line created">
                      <span class="orb-icon">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15" width="15" xmlns="http://www.w3.org/2000/svg">
                          <title>Created</title>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"></path>
                        </svg>
                      </span>
                      <p class="orb-typography p no-margin">{{ shortlink.data }}</p>
                    </div>
                  </div>
                  <div class="bottom-links">
                    <div class="link short">
                      <span class="orb-icon" title="Links Icon">
                        <svg viewBox="0 0 20 20" height="15" width="15">
                          <path fill="#273144" d="M4.917 8.833c-.5-.583-.75-1.25-.75-1.916 0-.75.25-1.417.833-1.917 1-1 2.833-1 3.833 0l1.834 1.833a.805.805 0 001.166 0 .805.805 0 000-1.166L10 3.75C9.167 3 8 2.5 6.833 2.5c-1.166 0-2.25.5-3.083 1.25-.833.833-1.25 2-1.25 3.167 0 1.166.417 2.25 1.25 3.083l1.833 1.833a.9.9 0 00.584.25.9.9 0 00.583-.25.805.805 0 000-1.166L4.917 8.833zM16.25 10l-1.833-1.833a.806.806 0 00-1.167 0 .806.806 0 000 1.166l1.833 1.917c.5.5.834 1.167.834 1.917s-.25 1.416-.834 1.916c-1.083 1.084-2.75 1.084-3.833 0L9.417 13.25a.806.806 0 00-1.167 0 .806.806 0 000 1.167l1.833 1.833c.834.833 2 1.25 3.084 1.25 1.083 0 2.25-.417 3.083-1.25.833-.833 1.25-1.917 1.25-3.083 0-1.25-.417-2.334-1.25-3.167z"></path>
                          <path fill="#273144" d="M12.25 12.25a.756.756 0 01-.583.25.757.757 0 01-.584-.25L7.75 8.917a.806.806 0 010-1.167.806.806 0 011.167 0l3.333 3.333a.806.806 0 010 1.167z"></path>
                        </svg>
                      </span>
                      <p class="orb-typography p no-margin">
                        <template v-if="shortlink.qrcode_link_type === 'direct'">
                          <a :href="shortlink.qrcode_link" target="_blank">
                            {{ shortlink.qrcode_link }}
                          </a>
                        </template>
                        <template v-else>
                          <a :href="'http://plink.io/' + shortlink.qrcode_shortlink" target="_blank">
                            plink.io/{{ shortlink.qrcode_shortlink }}
                          </a>
                        </template>
                      </p>
                    </div>
                    <div class="link long wrapped">
                      <span class="orb-icon">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><title>long url</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"></path></svg>
                      </span>
                      <p class="orb-typography p no-margin">
                        <template v-if="shortlink.qrcode_link_type === 'direct'">
                          no redirect
                        </template>
                        <template v-else>
                          <a :href="shortlink.qrcode_link" target="_blank">
                            {{ shortlink.qrcode_link }}
                          </a>
                        </template>

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" aria-label="Previous" @click.prevent="prevPage()">
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li class="page-item" v-for="page in pages" :key="page" :class="{ active: page === currentPage }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <a class="page-link" href="#" aria-label="Next" @click.prevent="nextPage()">
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import { endpointUrl } from '../../api'; // importa la funzione removeToken dal tuo modulo di autenticazione (api.js)

export default {
  name: 'QrcodeComponent',
  setup() {
    const shortlinks = ref([]);
    const searchTerm = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const isAllSelected = ref(false);
    const selectedShortlinkIds = ref([]);

    watch(shortlinks, () => {
      currentPage.value = 1;
      isAllSelected.value = false;
      selectedShortlinkIds.value = [];
    });

    const filteredShortlinks = computed(() => {
      const term = searchTerm.value.toLowerCase();
      return shortlinks.value.filter((shortlink) => {
        return shortlink.title.toLowerCase().includes(term) ||
            shortlink.qrcode_link.toLowerCase().includes(term) ||
            shortlink.qrcode_shortlink.toLowerCase().includes(term);
      });
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredShortlinks.value.length / itemsPerPage.value);
    });

    const paginatedShortlinks = computed(() => {
      const startIndex = itemsPerPage.value * (currentPage.value - 1);
      const endIndex = startIndex + itemsPerPage.value;
      return filteredShortlinks.value.slice(startIndex, endIndex);
    });

    const pages = computed(() => {
      const pagesArray = [];
      for (let i = 1; i <= totalPages.value; i++) {
        pagesArray.push(i);
      }
      return pagesArray;
    });

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const changePage = (page) => {
      currentPage.value = page;
    };

    const fetchShortlinks = async () => {
      try {

        const params = {
          qrcode_exist: 'qrcode_exist' // o 'direct', a seconda della tua esigenza
        };
        const response = await axios.get(endpointUrl('shortlink'), { params });
        //const response = await axios.get(endpointUrl('shortlink'));

        shortlinks.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const deleteShortlink = async (shortlinkId) => {
      const isConfirmed = confirm('Are you sure you want to delete this QR code?');
      if (!isConfirmed) {
        return;
      }
      try {
        const response = await axios.get(endpointUrl('shortlink/delete'), { params: { ids: [shortlinkId] } });

        console.log(response.data);
        shortlinks.value = shortlinks.value.filter(shortlink => shortlink.id !== shortlinkId);
      } catch (error) {
        console.error(error);
      }
    };

    const toggleShortlinkSelection = (shortlinkId) => {
      if (selectedShortlinkIds.value.includes(shortlinkId)) {
        selectedShortlinkIds.value = selectedShortlinkIds.value.filter((id) => id !== shortlinkId);
      } else {
        selectedShortlinkIds.value.push(shortlinkId);
      }
    };

    const selectAll = () => {
      if (selectedShortlinkIds.value.length === paginatedShortlinks.value.length) {
        selectedShortlinkIds.value = [];
      } else {
        selectedShortlinkIds.value = paginatedShortlinks.value.map((shortlink) => shortlink.id);
      }
    };

    const deleteSelected = async () => {
      const isConfirmed = confirm(`Are you sure you want to delete ${selectedShortlinkIds.value.length} selected QR codes?`);
      if (!isConfirmed) {
        return;
      }
      try {
        const response = await axios.get(endpointUrl('shortlink/delete'), { params: { ids: selectedShortlinkIds.value } });

        console.log(response.data);
        shortlinks.value = shortlinks.value.filter((shortlink) => !selectedShortlinkIds.value.includes(shortlink.id));
        isAllSelected.value = false;
        selectedShortlinkIds.value = [];
      } catch (error) {
        console.error(error);
      }
    };

    const downloadQrCodeFile = async (url, filename) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleDownloadQrCode = async (event) => {
      const element = event.target;
      const fileUrl = element.getAttribute('data-file');
      const fileName = element.getAttribute('data-filename');
      const extension = fileName.split('.').pop();
      const responseType = extension === 'svg' ? 'blob' : 'arraybuffer';

      try {
        const response = await axios.get(fileUrl, { responseType });
        const url = window.URL.createObjectURL(
            new Blob([response.data], { type: response.headers['content-type'] })
        );
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    };

    fetchShortlinks();

    return {
      shortlinks,
      searchTerm,
      currentPage,
      itemsPerPage,
      isAllSelected,
      selectedShortlinkIds,
      filteredShortlinks,
      totalPages,
      paginatedShortlinks,
      pages,
      prevPage,
      nextPage,
      changePage,
      deleteShortlink,
      toggleShortlinkSelection,
      selectAll,
      deleteSelected,
      downloadQrCodeFile,
      handleDownloadQrCode,
    };
  },
};
</script>

<style scoped>
.search-input {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.qrc-bulk-delete {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.qrc-bulk-delete label {
  margin-left: 10px;
  margin-right: 20px;
}

.qrc-card-container {
  display: flex;
  margin-bottom: 20px;
}

.qrc-card-container > .image-container {
  margin-right: 20px;
}

.qrc-actions {
  margin-top: 10px;
}

.qrc-actions button:not(:first-child) {
  margin-left: 5px;
}

.qrc-actions .btn-danger {
  margin-left: auto;
}

.qrc-actions .btn-danger svg {
  height: 1em;
  fill: #fff;
  width: 1em;
}
.qrc-actions .btn-danger svg path {
  fill: #fff;
}

.qrc-list-wrapper .pagination {
  margin-top: 20px;
}


.qrc-card-container .ml-2 {
  margin-left: 10px;
}
.qrc-card-container .image-container .qrc-image {
  width: 6em;
  height: 6em;
}
.qrc-card-container .card-content .content-bottom .bottom-links .short {
  margin-bottom: 0.2em;
}
.qrc-card-container .card-content .content-bottom .bottom-links .link.long {
  margin-left: 1em;
}
.qrc-card-container .card-content .content-bottom .bottom-links .link .orb-icon {
  line-height: 0;
  margin-right: 0.7em;
}
</style>