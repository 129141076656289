<template>
  <div class="content-header">
    <h1 class="section--header">Links</h1>

    <div class="list-filter">
      <input v-model="searchQuery" placeholder="Search..." class="ml-auto search-input" @input="filterShortlinks" />
    </div>

    <div class="d-flex justify-content-between align-items-center pt-4">
      <div>
        <button class="btn btn-sm btn-danger me-2" @click="deleteSelectedShortlinks"><i class="bi bi-trash"></i></button>
      </div>
    </div>
  </div>

  <div class="container-fluid ps-0">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="list">
          <div class="d-flex justify-content-between align-items-center pt-4">
            <div class="fw-bold">{{ totalShortlinks }} links</div>
          </div>

          <div v-if="isLoading" class="skeleton-wrapper">
            <div v-for="idx in 10" :key="idx" class="skeleton-item">
              <div class="skeleton-image"></div>
              <div class="skeleton-details">
                <div class="skeleton-details-title"></div>
                <div class="skeleton-line"></div>
              </div>
            </div>
          </div>

          <div v-else>
            <div v-for="(shortlink, index) in filteredShortlinks" :key="shortlink.id">
              <div class="link-item" :class="{ active: index === selectedLinkIndex }">
                <span class="link-item--checkbox">
                  <div class="checkbox--SMALL" :id="shortlink.id">
                    <input :id="shortlink.id" type="checkbox" class="checkbox--input" v-model="selectedShortlinkIds" :value="shortlink.id">
                    <label tabindex="0" :for="shortlink.id" class="checkmark-icon checkbox-icon"></label>
                  </div>
                </span>
                <a @click="getShortlinkById(shortlink.id); selectedLinkIndex = index" class="link-info">
                  <time class="link-item--created-date" :datetime="shortlink.date">{{ shortlink.data }}</time>
                  <div class="link-item--title">{{ shortlink.title }}</div>
                  <div class="link" tabindex="-1" :title="shortlink.qrcode_shortlink">plink.io/{{ shortlink.qrcode_shortlink }}</div>
                </a>
                <button class="btn btn-sm d-none d-md-block btn-danger float-end" @click="confirmDeleteShortlink(shortlink.id)">
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="filteredShortlinks.length === 0 && !isLoading" class="text-center p-4 mt-4 w-100">
          No links found
        </div>
      </div>

      <div class="col-12 col-md-8 sticky-top">
        <div v-if="isLoadingDetails" class="d-flex align-items-center justify-content-center p-4 h-100">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>

        <LinksDetails :shortlinkDetails="shortlinkDetails" v-if="shortlinkDetails.id && !isLoadingDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import LinksDetails from './LinksDetails.vue';
import axios from 'axios';
import { endpointUrl } from '../../api'; // importa la funzione removeToken dal tuo modulo di autenticazione (api.js)

export default {
  name: 'LinksComponent',

  data() {
    return {
      shortlinks: [],
      shortlinkDetails: {},
      selectedLinkIndex: null,
      searchQuery: '',
      isLoading: false,
      isLoadingDetails: false,
      selectedShortlinkIds: [],
    };
  },

  components: {
    LinksDetails,
  },

  computed: {
    filteredShortlinks() {
      return this.shortlinks.filter((shortlink) => {
        return shortlink.title.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },

    totalShortlinks() {
      return this.shortlinks.length;
    },
  },

  methods: {
    filterShortlinks() {
      this.selectedShortlinkIds = [];
    },

    async getShortlinkById(id) {
      try {
        this.isLoadingDetails = true;
        const response = await axios.get(endpointUrl(`shortlink?id=${id}`));
        this.shortlinkDetails = response.data[0];
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingDetails = false; // Set isLoadingDetails to false when the API call is complete
      }
    },

    confirmDeleteShortlink(id) {
      if (window.confirm('Are you sure you want to delete this link?')) {
        this.deleteShortlink(id);
      }
    },

    async deleteShortlink(id) {
      try {
        const response = await axios.post(endpointUrl('shortlink/delete_links'), { id });
        this.shortlinks = this.shortlinks.filter((shortlink) => shortlink.id !== response.data.id);
      } catch (error) {
        console.error(error);
      }
    },

    async deleteSelectedShortlinks() {
      if (this.selectedShortlinkIds.length === 0) {
        window.alert('Select at least one link to delete');
        return;
      }

      if (window.confirm(`Are you sure you want to delete ${this.selectedShortlinkIds.length} links?`)) {
        try {
          await axios.post(endpointUrl('shortlink/delete_links'), { ids: this.selectedShortlinkIds });
          this.selectedShortlinkIds.forEach((id) => {
            this.shortlinks = this.shortlinks.filter((shortlink) => shortlink.id !== id);
          });
          this.selectedShortlinkIds = [];
        } catch (error) {
          console.error(error);
        }
      }
    },

    async fetchShortlinks() {
      try {
        this.isLoading = true;

        const params = {
          qrcode_link_type: 'shortlink' // o 'direct', a seconda della tua esigenza
        };
        const response = await axios.get(endpointUrl('shortlink'), { params });
        //const response = await axios.get(endpointUrl('shortlink'));
        this.shortlinks = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false; // Set isLoading to false when the API call is complete
      }
    },
  },

  async created() {
    await this.fetchShortlinks();
  },
};

</script>

<style>
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.skeleton-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.skeleton-image {
  width: 80px;
  height: 80px;
  background-color: #ccc;
  border-radius: 5px;
}

.skeleton-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.skeleton-details-title {
  width: 60%;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
}

.skeleton-line {
  height: 15px;
  width: 50%;
  background-color: #ccc;
  border-radius: 5px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.list .link-item,
.list .link-item.active {
  justify-content: space-between;
}

.link-item .link-info {
  flex: 1;
}

.search-input {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
</style>