<template>

  <form @submit.prevent="createQRCode">

            <div class="title-wrapper">
              <h1 class="orb-typography h1">Inserisci dati di destinazione</h1>
            </div>

            <div class="mb-5">
              <label for="qrcode_link" class="form-label"
              >Destination URL</label
              >
              <input
                  id="qrcode_link"
                  placeholder="https://example.com/my-long-url"
                  class="form-control"
                  autocomplete="off"
                  maxlength="6144"
                  tabindex="0"
                  v-model="qrcode_link"
              />
            </div>

            <div class="mb-5">
              <label for="title" class="form-label"
              >Titolo <small>(Facoltativo)</small></label
              >
              <input
                  id="title"
                  class="form-control"
                  autocomplete="off"
                  maxlength="2048"
                  tabindex="0"
                  v-model="title"
              />
            </div>

            <h3 class="orb-typography h3 pb-1">Shortlink</h3>

            <p class="link-info--help-text">
              Lo shortlink indirizza gli utenti al sito web o al contenuto collegato
              al tuo codice QR. Ti consigliamo di utilizzare lo shortlink generato
              poiché in caso di modifiche all'URL di destinazione, lo shortlink
              continuerà a funzionare correttamente.
            </p>

            <div class="form-check form-switch custom-control-lg">
              <input
                  class="form-check-input"
                  type="checkbox"
                  id="qrcode_link_type"
                  name="qrcode_link_type"
                  :true-value="'shortlink'"
                  :false-value="'direct'"
                  v-model="useShortlink"
              />
              <label class="form-check-label" for="qrcode_link_type">
                Abilita questa opzione per generare un QRCode utilizzando lo
                shortlink anziché l'URL di destinazione.
              </label>
            </div>


            <button class="btn btn-primary my-5" type="submit">
              <svg viewBox="0 0 20 20" height="20" width="20">
                <path
                    d="M4.167 9.167H7.5c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167V7.5c0 .917.75 1.667 1.667 1.667zm0-5H7.5V7.5H4.167V4.167zm0 13.333H7.5c.917 0 1.667-.75 1.667-1.667V12.5c0-.917-.75-1.667-1.667-1.667H4.167c-.917 0-1.667.75-1.667 1.667v3.333c0 .917.75 1.667 1.667 1.667zm0-5H7.5v3.333H4.167V12.5zm6.666-5V4.167c0-.917.75-1.667 1.667-1.667h3.333c.917 0 1.667.75 1.667 1.667V7.5c0 .917-.75 1.667-1.667 1.667H12.5c-.917 0-1.667-.75-1.667-1.667zm1.667 0h3.333V4.167H12.5V7.5zm5 9.583v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.184.417.417.417h.833a.413.413 0 00.417-.417zm-6.667-5v-.833c0-.233.184-.417.417-.417h.833c.234 0 .417.184.417.417v.833a.413.413 0 01-.417.417h-.833a.413.413 0 01-.417-.417zm2.917.417h-.833a.413.413 0 00-.417.417v.833c0 .233.183.417.417.417h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417zm-2.917 2.917v-.834c0-.233.184-.416.417-.416h.833c.234 0 .417.183.417.416v.834a.413.413 0 01-.417.416h-.833a.413.413 0 01-.417-.416zm2.084 2.083h.833a.413.413 0 00.417-.417v-.833a.413.413 0 00-.417-.417h-.833a.413.413 0 00-.417.417v.833c0 .234.183.417.417.417zm2.5-1.667h-.834a.413.413 0 01-.416-.416v-.834c0-.233.183-.416.416-.416h.834c.233 0 .416.183.416.416v.834a.413.413 0 01-.416.416zm0-5h-.834a.413.413 0 00-.416.417v.833c0 .234.183.417.416.417h.834a.413.413 0 00.416-.417v-.833a.413.413 0 00-.416-.417zm1.666 3.334h-.833a.413.413 0 01-.417-.417v-.833c0-.234.184-.417.417-.417h.833c.234 0 .417.183.417.417v.833a.413.413 0 01-.417.417z"
                    clip-rule="evenodd"
                ></path>
              </svg>
              Crea codice QR
            </button>
            <div v-if="message" class="alert alert-success mt-3">
              {{ message }}
            </div>




</form>



</template>

<script>
import axios from 'axios';
import { getUserData } from '../../api';

export default {
  name: 'NewQrcode',
  data() {
    return {
      qrcode_link: '',
      title: '',
      useShortlink: false,
      qrcode_link_type: 'direct',
      message: '',
      user: ''
    };
  },
  methods: {
    async createQRCode() {
      if (this.qrcode_link === '') {
        return;
      }
      let qrcode_link_type = this.useShortlink ? 'shortlink' : 'direct'; // imposto qrcode_link_type a "shortlink" o "direct" in base al valore di "useShortlink"

      const endpoint = 'https://api.principiadv.online/wp-json/shortlink/v1/shortlink/add_qrcode';
      const data = {
        destination_url: this.qrcode_link,
        title: this.title,
        use_shortlink: this.useShortlink,
        qrcode_link_type: qrcode_link_type,
        post_author: this.user.user_id
      };

      try {
        const response = await axios.post(endpoint, data);
        this.message = response.data.message;
        this.qrcode_link = '';
        this.title = '';
        this.useShortlink = false;
        this.qrcode_link_type = 'direct';
      } catch(error) {
        console.log(error);
        this.message = 'Errore durante la creazione del QR code.';
      }
    },
    async getUser() {
      try {
        const userData = await getUserData();
        this.user = userData;
      } catch(error) {
        console.error(error);
      }
    }
  },
  async mounted() {
    await this.getUser();
  }
};

</script>